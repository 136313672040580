<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <div class="page-content pb-0">
      <div class="card rounded-m mb-0">
        <div class="content">
          <h4>{{ jobTitle }}</h4>
          <div class="mt-5 mb-3">
            <!-- Form -->
            <!-- Basic Information -->
            <p class="font-18 font-500 mb-3 color-highlight">Informasi Dasar</p>

            <div v-if="errors" class="mb-3">
              <p class="color-red-dark">
                {{ errors.errorMessage }}
              </p>
            </div>
            <div
              class="input-style no-borders no-icon validate-field"
              :class="{ 'input-style-active': formData.title }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1"
                v-model="formData.title"
                placeholder="Nama Pekerjaan"
                required
              />
              <label for="form1" class="">Nama</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em>(required)</em>
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': formData.about }"
            >
              <textarea
                id="form1h"
                v-model="formData.about"
                placeholder="Tentang Pekerjaan"
                required
              ></textarea>
              <label for="form1h" class="">Tentang Pekerjaan</label>
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': formData.description }"
            >
              <textarea
                id="form1d"
                v-model="formData.description"
                placeholder="Deskripsi Pekerjaan"
                required
              ></textarea>
              <label for="form1d">Deskripsi</label>
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': formData.requirement }"
            >
              <textarea
                id="form1b"
                v-model="formData.requirement"
                placeholder="Kualifikasi"
                required
              >
              </textarea>
              <label for="form1b" class="">Kualifikasi</label>
            </div>

            <div
              class="input-style no-borders no-icon"
              :class="{ 'input-style-active': formData.skill_desc }"
            >
              <textarea
                id="form1h"
                v-model="formData.skill_desc"
                placeholder="Deskripsi keahlian yang di butuhkan"
                required
              ></textarea>
              <label for="form1h" class="">Skill</label>
            </div>

            <div class="row">
              <label for="form1e">Gaji</label>
              <div
                class="input-style no-borders no-icon validate-field col-6"
                :class="{ 'input-style-active': formData.salary_start }"
              >
                <input
                  type="name"
                  class="form-control validate-name"
                  id="form1e"
                  v-model="formData.salary_start"
                  placeholder="9.000.000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>

              <div
                class="input-style no-borders no-icon validate-field col-6"
                :class="{ 'input-style-active': formData.salary_end }"
              >
                <input
                  type="name"
                  class="form-control validate-name"
                  id="form1f"
                  v-model="formData.salary_end"
                  placeholder="10.000.000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>
            </div>
            <div
              class="input-style no-borders no-icon validate-field"
              :class="{ 'input-style-active': formData.expire_at }"
            >
              <input
                type="date"
                class="custom-form-control validate-name"
                id="form1i"
                v-model="formData.expire_at"
                required
              />
              <label for="form1i" class="">Lowongan Berakhir</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em class="me-3">(required)</em>
            </div>
            <div class="d-flex">
              <div class="pt-1">
                <h5
                  data-data-trigger-switch="toggle-id-salary"
                  class="font-600 font-14"
                >
                  Sembunyikan Gaji
                  <p class="font-10 font-100 line-height-s">
                    *Gaji akan di sembunyikan
                  </p>
                </h5>
              </div>
              <div class="ms-auto me-3 pe-2">
                <div class="custom-control ios-switch small-switch">
                  <input
                    type="checkbox"
                    class="ios-input"
                    id="toggle-id-salary"
                    v-model="formData.hide_salary"
                  />
                  <label
                    class="custom-control-label"
                    for="toggle-id-salary"
                  ></label>
                </div>
              </div>
            </div>
            <!--End Basic Information -->

            <!-- Requirement -->
            <p class="font-18 font-500 mb-3 mt-5 color-highlight">Kategori</p>

            <!-- Category -->

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select class="form-control" id="form1bc" v-model="categoryL1">
                <option value="default" disabled selected>
                  Kategori Level 1
                </option>
                <option
                  v-for="category in category.level1"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
              <label for="form1bc">Kategori Level 1</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL1"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
              <p class="font-10 font-100 line-height-s">
                * Kategori Umum, misal Akuntansi dan Keuangan
              </p>
            </div>
            <!-- Category level 2 -->

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
                mt-4
              "
            >
              <select
                v-if="!addLevel2"
                class="form-control"
                id="form1bd"
                v-model="categoryL2"
              >
                <option value="default" disabled selected>
                  Kategori Level 2
                </option>
                <option
                  v-for="category in category.level2"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>

              <label for="form1bd">Kategori level 2</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL2"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
              <p class="font-10 font-100 line-height-s">
                * Sub kategori, misal Kasir
              </p>
            </div>

            <!-- Category level 3 -->
            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
                mt-4
              "
            >
              <select
                v-if="!addLevel3"
                class="form-control"
                id="form1be"
                v-model="categoryL3"
              >
                <option value="default" disabled selected>
                  Kategori Pekerjaan
                </option>
                <option
                  v-for="category in category.level3"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>

              <label for="form1be">Kategori</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <p class="font-10 font-100 line-height-s">
                *Optional, Kategori spesifik, misal Staf kasir
              </p>
            </div>

            <!-- End Category -->
            <div
              class="
                input-style
                no-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': formData.job_type }"
            >
              <select class="form-control" id="form1a" v-model="formData.job_type">
                <option
                  v-for="type in options.job_type"
                  :key="type.value"
                  :value="type.value"
                  disabled
                >
                  {{ type.label }}
                </option>
              </select>
              <label for="form1a" class="">Tipe Pekerjaan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select
              class="form-control"
                v-if="formData.job_type === 'VC'"
                id="form1a"
                v-model="formData.level"
              >
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="level in master_data.job_level"
                  :key="level.id"
                  :value="level.id"
                >
                  {{ level.name }}
                </option>
              </select>
              <label for="form1a">Level</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select id="form1g" class="form-control" v-model="formData.worktime">
                <option value="default" disabled="">Select a Value</option>
                <option
                  v-for="worktime in options.worktime"
                  :key="worktime.value"
                  :value="worktime.value"
                >
                  {{ worktime.label }}
                </option>
              </select>
              <label for="form1h">WorkTime</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>

            <div class="d-flex mb-3">
              <div class="pt-1">
                <h5
                  data-data-trigger-switch="toggle-id-2"
                  class="font-600 font-14"
                >
                  Remote
                  <p class="font-10 font-100 line-height-s">
                    *Pekerjaan dilakukan secara daring
                  </p>
                </h5>
              </div>
              <div class="ms-auto me-3 pe-2">
                <div class="custom-control ios-switch small-switch">
                  <input
                    type="checkbox"
                    class="ios-input"
                    id="toggle-id-2"
                    v-model="formData.remote"
                  />
                  <label class="custom-control-label" for="toggle-id-2"></label>
                </div>
              </div>
            </div>
            <!--End Requirement -->

            <!-- Location -->
            <p class="font-18 font-500 mb-3 mt-5 color-highlight">
              Lokasi Bekerja
            </p>

            <div
              class="input-style no-borders no-icon mb-4"
              :class="{ 'input-style-active': formData.work_loc_address }"
            >
              <textarea
                id="form1b"
                v-model="formData.work_loc_address"
                required
                maxlength="150"
                placeholder="Alamat Pekerjaan"
              ></textarea>
              <label for="form1b">Alamat</label>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select id="form1a" class="form-control" v-model="formData.work_loc_country">
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="country in master_data.country"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label for="form1a">Country</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select id="form1a" class="form-control" v-model="work_loc_province">
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option
                  v-for="province in master_data.province"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
              <label for="form1a">Province</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>

            <div
              class="
                input-style
                no-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select id="form1a" class="form-control" v-model="formData.work_loc_city">
                <option value="default" disabled="" selected="">
                  Select a Value
                </option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <label for="form1a">City</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em class="me-3">(required)</em>
            </div>
            <!-- End location -->
          </div>
          <a
            @click.prevent="submitForm"
            href="#"
            class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >{{ btnName }}</a
          >
          <router-link
            :to="{ name: 'Dashboard' }"
            href="#"
            class="
              btn btn-full btn-m
              bg-red-dark
              rounded-s
              font-13 font-600
              mt-4
            "
            >Batal</router-link
          >
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="update-job-modals"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Berhasil diubah</h3>
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
/*import "../assets/code/scripts/custom.js";*/
import Header from "@/components/Header.vue";
export default {
  name: "UpdateJob",
  components: {
    Header,
  },
  inject: ["getMasterData"],
  data() {
    return {
      formData: {
        title: "",
        job_type: "",
        category: "",
        level: "",
        work_loc_address: "",
        requirement: "",
        work_loc_country: "",
        work_loc_city: "",
        work_loc_gps: "",
        description: "",
        salary_start: "",
        salary_end: "",
        worktime: "",
        remote: false,
        hide_salary: false,
        skill_desc: "",
        tags: "",
        about: "",
        expire_at: "",
      },
      categoryL1: "",
      categoryL2: "",
      categoryL3: "",
      skills: [],
      work_loc_province: "",
      cities: [],

      options: {
        worktime: [
          { value: "FT", label: "Full Time" },
          { value: "PT", label: "Part Time" },
          { value: "FL", label: "Freelance" },
          { value: "IT", label: "Internship" },
        ],
        job_type: [
          { value: "VC", label: "Vacancy" },
          { value: "PJ", label: "Project" },
        ],
      },
      api_url: "/api/v1/employer/job/update/",
      master_data: {},

      category: {
        level1: [],
        level2: [],
        level3: [],
      },
      addLevel2: false,
      addLevel3: false,
      jobTitle: "",
      btnName: "Ubah",
      errors: [],
      isLoading: true,
    };
  },
  watch: {
    work_loc_province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.work_loc_province;
      });
      this.cities ? (this.cities = this.cities[0].city) : "";
    },
    categoryL1() {
      this.category.level2 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL1;
      });
      this.addLevel2 = false;
      this.addLevel3 = false;
    },

    categoryL2() {
      if (!(this.categoryL2 === "addL2") && !(this.categoryL2 === "")) {
        this.category.level3 = this.allCategory.filter((item) => {
          return item.parent === this.categoryL2;
        });
      }
      if (this.categoryL2 === "addL2") {
        this.addLevel2 = true;
        this.categoryL2 = "";
      }
      this.addLevel3 = false;
    },

    categoryL3() {
      if (this.categoryL3 === "addL3") {
        this.addLevel3 = true;
        this.categoryL3 = "";
      }
    },
  },
  mounted() {
    init_template();
    this.master_data = this.$store.state.master_data;
    this.getCategory();
    this.getData();
    this.$route.query.recreate ? (this.btnName = "Tambah") : "false";
  },
  methods: {
    menuClose,
    menuOpen,
    async getCategory() {
      await axios
        .get("api/v1/employer/category/?all_category=true")
        .then((response) => {
          this.allCategory = response.data;
          this.category.level1 = response.data.filter((item) => {
            return item.level === "L1";
          });
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
            /*import "../assets/code/scripts/custom.js";*/
            this.isLoading = false;
            this.submit = false;
          }
        });
    },
    getData() {
      var id = this.$route.params.id;
      this.api_url = this.api_url + id + "/";
      axios
        .get(this.api_url)
        .then((response) => {
          this.formData = response.data;
          this.work_loc_province = response.data.work_loc_province;
          this.isLoading = false;
          const categoryL3 = this.allCategory.filter((item) => {
            return item.id === response.data.category;
          });
          const categoryL2 = this.allCategory.filter((item) => {
            return item.id === categoryL3[0].parent;
          });
          if (!categoryL3.level === "L2") {
            this.categoryL1 = this.allCategory.filter((item) => {
              return item.id === categoryL2[0].parent;
            })[0].id;
            this.categoryL2 = categoryL2[0].id;
            this.categoryL3 = categoryL3[0].id;
          }

          this.categoryL1 = categoryL2[0].id;
          this.categoryL2 = categoryL3[0].id;
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    validateInput() {
      this.errors = {};
      try {
        if (
          parseInt(this.formData.salary_start) >
          parseInt(this.formData.salary_end)
        ) {
          this.errors["pref_salary_start"] =
            "Tidak boleh lebih dari gaji terakhir";
          this.errors["errorMessage"] =
            "Gaji awal tidak boleh lebih dari gaji terakhir";
        }
      } catch {
        console.log("input must number");
      }
      if (Object.keys(this.errors).length) {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    async submitForm() {
      this.validateInput();
      if (!Object.keys(this.errors).length) {
        var data = this.formData;
        data.category = this.categoryL3;
        data.work_loc_province = this.work_loc_province;
        var url = this.$route.query.recreate
          ? "/api/v1/employer/job/"
          : this.api_url;
        var method = this.$route.query.recreate
          ? axios.post(url, data)
          : axios.patch(url, data);
        await method
          .then((response) => {
            this.menuOpen("update-job-modals");

            var job_type =
              this.formData.job_type === "VC" ? "vacancy" : "project";
            setTimeout(() => {
              this.$router.push({
                name: "CreateJob",
                params: { job_type: job_type },
              });
            }, 2000);
          })
          .catch((error) => {
            this.errors = [];
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors.push("Something went wrong please try again");
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>

<style>
.custom-form-control {
  background-color: #fff;
  color: #212529;
  border: 1px solid #ced4da;
}
</style>
